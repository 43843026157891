import React from 'react';
import { ParagraphContainer } from '../../../generated/types';
import { default as BaseContainer } from '../../Layout/Container';
import PARAGRAPH_MAPPING from '../../../config/paragraph-mapping';
import Wrapper from '../Wrapper';

export interface ContainerProps {
  data: ParagraphContainer;
  isInArticleLayout?: boolean;
}

const Container: React.FC<ContainerProps> = ({ data, isInArticleLayout }) => {
  const Inner = data.content?.map((data) => {
    if (PARAGRAPH_MAPPING[data.__typename]) {
      const Component = PARAGRAPH_MAPPING[data.__typename];

      if (!Component) return null;

      return (
        <Component
          key={data.id}
          data={data}
          isInArticleLayout={isInArticleLayout}
        />
      );
    }

    return null;
  });

  return (
    <Wrapper
      className="ParagraphContainer"
      __css={{
        '.ParagraphText': {
          marginY: 2,
        },
        '.ParagraphContainer': {
          px: 0,
        },
      }}
    >
      {isInArticleLayout ? (
        Inner
      ) : (
        <BaseContainer smaller={data.size === 'smaller'} marginY={8}>
          {Inner}
        </BaseContainer>
      )}
    </Wrapper>
  );
};

export default Container;
